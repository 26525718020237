import { defineNuxtPlugin } from '#app/nuxt'
import { LazyAsyncPageDependency, LazyNuxtAsyncPage, LazyVuepalRokkaImage, LazyVuepalLocalTasks, LazyVuepalAdminToolbar, LazySpriteSymbol, LazyRenderCacheable, LazyBlokkliField, LazyBlokkliEditable, LazyBlokkliProvider, LazyBlokkliItem } from '#components'
const lazyGlobalComponents = [
  ["AsyncPageDependency", LazyAsyncPageDependency],
["NuxtAsyncPage", LazyNuxtAsyncPage],
["VuepalRokkaImage", LazyVuepalRokkaImage],
["VuepalLocalTasks", LazyVuepalLocalTasks],
["VuepalAdminToolbar", LazyVuepalAdminToolbar],
["SpriteSymbol", LazySpriteSymbol],
["RenderCacheable", LazyRenderCacheable],
["BlokkliField", LazyBlokkliField],
["BlokkliEditable", LazyBlokkliEditable],
["BlokkliProvider", LazyBlokkliProvider],
["BlokkliItem", LazyBlokkliItem],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
