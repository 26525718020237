import { defineNuxtPlugin } from '#imports'
import { TranslationsQuery } from '#graphql-operations'

// @ts-ignore
export default defineNuxtPlugin(async () => {
  const { data } = await useInitData()

  const getTranslationValue = (key: string): string[] => {
    const field = key.replaceAll('.', '__') as keyof TranslationsQuery
    return [data.value.translations[field]]
  }

  return {
    provide: {
      /**
       * Request a text for a key.
       *
       * @param {string} key - The unique text key. Optionally prefixed by a context, e.g. "footer.copyright".
       * @returns {string} - The text.
       */
      texts: (key: string, _defaultText?: string): string => {
        const value = getTranslationValue(key)
        return value[0] || key
      },

      /**
       * Request a pluralized text.
       *
       * The correct text is returned based on the given count.
       *
       * @param {string} key - The unique text key. Optionally prefixed by a context, e.g. "footer.copyright".
       * @param {number} count - The count.
       */
      textsPlural(key: string, count = 0) {
        const value = getTranslationValue(key)
        if (count !== 1 && value[1]) {
          return value[1].replace('@count', count.toString())
        }

        return value[0] || key
      },
    },
  }
})
