<template>
  <template v-if="MOBILE_ICONS.includes(name)">
    <SpriteSymbol
      :name="name + '_mobile'"
      :class="[$attrs.class, 'lg:hidden']"
    />
    <SpriteSymbol :name="name" :class="[$attrs.class, 'until-lg:hidden']" />
  </template>
  <SpriteSymbol v-else :name="name" :class="$attrs.class" />
</template>

<script lang="ts" setup>
import type { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite'

const MOBILE_ICONS = [
  'pin',
  'mail',
  'menu',
  'plus',
  'arrow',
  'cross',
  'minus',
  'bullet',
  'search',
  'download',
  'chevron-up',
  'chevron-down',
  'chevron-left',
  'chevron-right',
]

defineProps<{
  name: NuxtSvgSpriteSymbol
}>()
</script>
