<template>
  <div v-if="mapped.length" class="menu-group until-md:border-b-1">
    <button
      class="is-small claim until-md:py-30 until-md:flex until-md:justify-between until-md:items-center until-md:w-full md:pointer-events-none focus:outline-none md:mb-20"
      tabindex="-1"
      @click="isOpen = !isOpen"
    >
      <span>{{ name }}</span>
      <div class="md:hidden">
        <ResponsiveSpriteSymbol
          :name="isOpen ? 'minus' : 'plus'"
          class="w-17 h-17"
        />
      </div>
    </button>
    <Component :is="isMobile ? Collapse : 'div'" :when="isOpen">
      <ul class="space-y-20 until-md:pb-20">
        <li v-for="(link, index) in mapped" :key="`menulink-${index}`">
          <DrupalLink :to="link.to" class="link is-3">{{
            link.label
          }}</DrupalLink>
        </li>
      </ul>
    </Component>
  </div>
</template>

<script lang="ts" setup>
import { MainMenuLinkFragment } from '#graphql-operations'
import { falsy } from '~/helpers/type'
import { Collapse } from 'vue-collapsed'

const props = defineProps<{
  name?: string
  links?: MainMenuLinkFragment[]
}>()

const { isMobile } = useViewport()
const isOpen = ref(false)

const mapped = computed(() => {
  return (
    props.links
      ?.map((v) => {
        if (v.link.label && v.link.url?.path) {
          return {
            label: v.link.label,
            to: v.link.url?.path,
          }
        }
      })
      .filter(falsy) || []
  )
})
</script>
