<template>
  <nav
    class="flex flex-wrap items-center justify-between sm:justify-start gap-20"
  >
    <a
      v-for="link in links"
      :href="link.href"
      target="_blank"
      rel="nofollow"
      class="link"
    >
      <SpriteSymbol :name="link.icon" class="w-30 h-30" />
    </a>
  </nav>
</template>

<script lang="ts" setup>
import { NuxtSvgSpriteSymbol } from '#nuxt-svg-sprite'

const { isImperial } = useCountry()

type SocialLink = {
  href: string
  icon: NuxtSvgSpriteSymbol
  label: string
}

const buildLink = (
  icon: NuxtSvgSpriteSymbol,
  label: string,
  href: string,
  imperialHref?: string,
): SocialLink => {
  return {
    href: imperialHref && isImperial.value ? imperialHref : href,
    icon,
    label,
  }
}

const links = computed(() => [
  buildLink(
    'youtube',
    'YouTube',
    'https://www.youtube.com/user/ExpedLLC/featured',
  ),
  buildLink(
    'instagram',
    'Instagram',
    'https://www.instagram.com/expedint/',
    'https://www.instagram.com/expedusa/?hl=en',
  ),
  buildLink('facebook', 'Facebook', 'https://www.facebook.com/EXPEDint/'),
  buildLink(
    'linkedin',
    'LinkedIn',
    'https://www.linkedin.com/company/69548006/admin/',
    'https://www.linkedin.com/company/expedusa/',
  ),
  buildLink('tiktok', 'TikTok', 'https://www.tiktok.com/@expedint'),
])
</script>
