<template>
  <div class="user-toolbar-container">
    <div class="container flex justify-end bg-white">
      <DrupalLink
        v-if="overviewUrl"
        :to="overviewUrl"
        :title="$texts('overviewLabel', 'Overview')"
        :class="{ 'is-active': isOverviewActive }"
      >
        {{ $texts('overviewLabel', 'Overview') }}
      </DrupalLink>
      <DrupalLink
        v-if="profileUrl"
        :to="profileUrl"
        :title="$texts('profileLabel', 'Profile')"
      >
        {{ $texts('profileLabel', 'Profile') }}
      </DrupalLink>
      <a :href="`/${language}/user/logout`" :title="$texts('logoutLabel', 'Logout')">
        {{ $texts('logoutLabel', 'Logout') }}
      </a>
    </div>
  </div>
</template>

<script lang="ts" setup>
const language = useCurrentLanguage()
const route = useRoute()
const drupalUser = useDrupalUser()

const groups = drupalUser.groups
const overviewUrl = computed(() => {
  if (groups.value.length === 1 && groups.value[0].url?.path) {
    return groups.value[0].url.path
  } else if (groups.value.length !== 0) {
    return `/${language}/groups`
  }
  return ''
})

const profileUrl = computed(() => '/user')
const isOverviewActive = computed(() => {
  if (route.name === 'groups') {
    return true
  }
  return groups.value.some((group: any) => group.url.path === route.path)
})
</script>

<style lang="scss" scoped>
.user-toolbar-container {
  @apply uppercase flex justify-end text-sm z-50;
  box-shadow: 0 0 10px rgba(0, 0, 0, .1);
  a {
    @apply block py-20 px-20 font-bold;
    letter-spacing: 0.07em;
    &:hover,
    &:active,
    &.is-active {
      @apply text-gray-dark;
    }
  }
  .account-menu-item a {
    @apply block py-20 px-20;
    &:hover, &:active {
      @apply text-gray-dark;
    }
  }
}
</style>
