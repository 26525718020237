<template>
  <div class="flex h-50 items-center">
    <NuxtLink to="/en/sitemap" class="link uppercase font-bold mr-20"
      >Sitemap</NuxtLink
    >
    <CountrySwitcher v-slot="{ countries }">
      <div class="flex h-full">
        <button
          v-for="item in countries"
          @click="country = item.value"
          class="uppercase px-10 h-full flex items-center opacity-40 hover:opacity-70"
          :title="item.label"
          :class="{ 'font-bold !opacity-100': item.value === country }"
        >
          {{ item.value }}
        </button>
      </div>
    </CountrySwitcher>

    <LanguageSwitcher v-slot="{ languageLinks }">
      <div class="flex ml-30 h-full">
        <NuxtLink
          v-for="link in languageLinks"
          class="uppercase px-10 h-full flex items-center opacity-40 hover:opacity-70"
          :class="{ 'font-bold !opacity-100': link.value === language }"
          :title="link.label"
          :to="link.href"
        >
          {{ link.value }}
        </NuxtLink>
      </div>
    </LanguageSwitcher>
  </div>
</template>

<script lang="ts" setup>
const { country } = useCountry()
const language = useCurrentLanguage()
</script>
