<template>
  <NuxtLink
    :to="url"
    class="content-search-result-item flex flex-wrap md:items-center"
  >
    <div class="mb-20 sm:mb-0 px-10 w-full sm:w-1/3 md:w-1/4 lg:w-1/5 aspect-1">
      <VuepalRokkaImage
        style-name="product_teaser"
        v-if="image"
        :file="image"
      />
    </div>
    <div class="px-10 w-full sm:w-2/3 md:w-3/4 lg:w-4/5">
      <h3 class="title is-3">{{ title }}</h3>
      <p class="mb-20">{{ productName }}</p>
      <p v-html="description"></p>
    </div>
  </NuxtLink>
</template>

<script lang="ts" setup>
import type { ImageFileFragment } from '#graphql-operations'

const props = defineProps<{
  id: string
  url: string
  title: string
  description: string
  teaserImage: string
  bundle: string
  productName: string
  entityType: string
}>()

const image = computed<ImageFileFragment | undefined>(() => {
  if (props.teaserImage) {
    return {
      label: props.id + '.jpg',
      rokka: {
        hash: props.teaserImage,
      },
    }
  }
})
</script>
