import { StaticNodeFragment } from '#graphql-operations'

type StaticRouteMapping = {
  languageMapping: Record<string, string>
}

type StaticRoutes = Record<string, StaticRouteMapping>

/**
 * Given the static page keys build the mapping to be used to alter the routes.
 */
function buildMapping(staticNodes: StaticNodeFragment[] = []): StaticRoutes {
  // @TODO: Get from runtime config.

  return (staticNodes || []).reduce<StaticRoutes>((acc, v) => {
    if (v.node?.id && v.key) {
      // const defaultMapping = buildDefaultMapping(v.node.id)

      // {
      //   de: '/de/suche',
      //   en: '/en/search',
      // }
      const languageMapping = (v.node.translations || []).reduce<
        Record<string, string>
      >((aliases, link) => {
        const id = link.langcode
        const url = link.url?.path
        if (id && url) {
          aliases[id] = url
        }
        return aliases
      }, {})

      acc[v.key] = { languageMapping }
    }

    return acc
  }, {})
}

/**
 * This plugin extends the available routes.
 *
 * Page components that define a staticDrupalPage meta key will have their
 * path overriden by the actual path from a connected Drupal node.
 */
export default defineNuxtPlugin({
  async setup(nuxtApp) {
    const router = useRouter()
    const language = useCurrentLanguage()

    // Find all static routes.
    const staticRoutes = router
      .getRoutes()
      .filter((v) => !!v.meta.staticDrupalPage)
    const keys = staticRoutes.map((v) => v.meta.staticDrupalPage) as string[]

    if (!keys.length) {
      return
    }

    const { data } = await useInitData()
    const mapped = buildMapping(data.value.staticNodes)

    async function setStaticRoutes() {
      // Loop over all static routes. The original route is removed, altered and
      // then added again, now including the correct paths from Drupal.
      staticRoutes.forEach((route) => {
        if (!route.name) {
          return
        }

        const staticKey = route.meta.staticDrupalPage

        if (!staticKey || typeof staticKey !== 'string') {
          return
        }

        const routeMapping = mapped[staticKey]
        if (!routeMapping) {
          return
        }
        router.removeRoute(route.name)
        route.meta.languageMapping = routeMapping.languageMapping
        router.addRoute({ ...route })
      })
    }

    await setStaticRoutes()
    watch(language, async () => {
      await setStaticRoutes()
    })
  },
})
