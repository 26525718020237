import { default as index6Px3pfWl3xMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/[...slug]/index.vue?macro=true";
import { default as index5eyBFV4ydQMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/faqs/index.vue?macro=true";
import { default as indexaOyuUUB0csMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/groups/index.vue?macro=true";
import { default as index5oJHHjT8WVMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/media/[id]/index.vue?macro=true";
import { default as indexLLQSlwAG1bMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/newsroom/index.vue?macro=true";
import { default as index6gFOJA45y3Meta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/products/[category]/[product]/index.vue?macro=true";
import { default as indexN41pjdT4n1Meta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/products/[category]/index.vue?macro=true";
import { default as indexWe2FFzAJNCMeta } from "/var/www/prod.exped.com/releases/202407300736/frontend/pages/sitemap/index.vue?macro=true";
export default [
  {
    name: index6Px3pfWl3xMeta?.name ?? "slug",
    path: index6Px3pfWl3xMeta?.path ?? "/:slug(.*)*",
    meta: index6Px3pfWl3xMeta || {},
    alias: index6Px3pfWl3xMeta?.alias || [],
    redirect: index6Px3pfWl3xMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/[...slug]/index.vue").then(m => m.default || m)
  },
  {
    name: index5eyBFV4ydQMeta?.name ?? "faqs",
    path: index5eyBFV4ydQMeta?.path ?? "/faqs",
    meta: index5eyBFV4ydQMeta || {},
    alias: index5eyBFV4ydQMeta?.alias || [],
    redirect: index5eyBFV4ydQMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/faqs/index.vue").then(m => m.default || m)
  },
  {
    name: indexaOyuUUB0csMeta?.name ?? "groups",
    path: indexaOyuUUB0csMeta?.path ?? "/groups",
    meta: indexaOyuUUB0csMeta || {},
    alias: indexaOyuUUB0csMeta?.alias || [],
    redirect: indexaOyuUUB0csMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/groups/index.vue").then(m => m.default || m)
  },
  {
    name: index5oJHHjT8WVMeta?.name ?? "media-id",
    path: index5oJHHjT8WVMeta?.path ?? "/media/:id()",
    meta: index5oJHHjT8WVMeta || {},
    alias: index5oJHHjT8WVMeta?.alias || [],
    redirect: index5oJHHjT8WVMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/media/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexLLQSlwAG1bMeta?.name ?? "newsroom",
    path: indexLLQSlwAG1bMeta?.path ?? "/newsroom",
    meta: indexLLQSlwAG1bMeta || {},
    alias: indexLLQSlwAG1bMeta?.alias || [],
    redirect: indexLLQSlwAG1bMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/newsroom/index.vue").then(m => m.default || m)
  },
  {
    name: index6gFOJA45y3Meta?.name ?? "products-category-product",
    path: index6gFOJA45y3Meta?.path ?? "/products/:category()/:product()",
    meta: index6gFOJA45y3Meta || {},
    alias: index6gFOJA45y3Meta?.alias || [],
    redirect: index6gFOJA45y3Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/products/[category]/[product]/index.vue").then(m => m.default || m)
  },
  {
    name: indexN41pjdT4n1Meta?.name ?? "products-category",
    path: indexN41pjdT4n1Meta?.path ?? "/products/:category()",
    meta: indexN41pjdT4n1Meta || {},
    alias: indexN41pjdT4n1Meta?.alias || [],
    redirect: indexN41pjdT4n1Meta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/products/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWe2FFzAJNCMeta?.name ?? "sitemap",
    path: indexWe2FFzAJNCMeta?.path ?? "/sitemap",
    meta: indexWe2FFzAJNCMeta || {},
    alias: indexWe2FFzAJNCMeta?.alias || [],
    redirect: indexWe2FFzAJNCMeta?.redirect || undefined,
    component: () => import("/var/www/prod.exped.com/releases/202407300736/frontend/pages/sitemap/index.vue").then(m => m.default || m)
  }
]