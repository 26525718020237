<template>
  <div id="nuxt-error-page">
    <!-- This will capture any errors that might happen when rendering the header. -->
    <!-- We don't want to trigger another error on the error page. -->
    <NuxtErrorBoundary>
      <AppHeader />
    </NuxtErrorBoundary>
    <!-- <template v-if="isErrorNotFound && entity"> -->
    <!--   <NodePage v-bind="entity" /> -->
    <!-- </template> -->
    <!-- <template v-else> -->
    <div class="container">
      <PageHeader :title="title" :lead="text" />
      <a v-if="goToHome" :href="frontUrl" class="button is-primary">{{
        goToHome
      }}</a>
    </div>
    <!-- </template> -->
    <NuxtErrorBoundary>
      <AppFooter />
    </NuxtErrorBoundary>
  </div>
</template>

<script setup lang="ts">
import {
  GroupFragment,
  NodeBlogArticleFragment,
  NodePageFragment,
} from '#graphql-operations'

/**
 * IMPORTANT: Do not use $texts here because the $texts plugin might not be
 * available because the plugin didn't initialize, which would again cause
 * an error.
 */
const route = useRoute()

interface ErrorMessages {
  accessDeniedTitle: string
  accessDeniedText: string
  pageNotFoundTitle: string
  pageNotFoundText: string
  serverErrorTitle: string
  goToHome: string
}

const ERRORS: Record<string, ErrorMessages> = {
  de: {
    accessDeniedTitle: 'Zugriff verweigert',
    accessDeniedText: 'Sie haben keinen Zugriff auf diese Seite.',
    pageNotFoundTitle: 'Seite nicht gefunden',
    pageNotFoundText:
      'Die von Ihnen angeforderte Seite konnte nicht gefunden werden. Benutzen Sie unsere Suchfunktion um den gewünschten Inhalt zu finden.',
    serverErrorTitle: 'Seite nicht verfügbar',
    goToHome: 'Zur Startseite',
  },
  fr: {
    accessDeniedTitle: 'Accès refusé',
    accessDeniedText: "Vous n'avez pas accès à cette page.",
    pageNotFoundTitle: 'Page introuvable',
    pageNotFoundText:
      "La page que vous avez demandée n'a pas pu être trouvée. Utilisez notre fonction de recherche pour trouver le contenu que vous recherchez.",
    serverErrorTitle: 'Page non disponible',
    goToHome: 'Aller à la page d’accueil',
  },
  it: {
    accessDeniedTitle: 'Accesso negato',
    accessDeniedText: 'Non hai accesso a questa pagina.',
    pageNotFoundTitle: 'Pagina non trovata',
    pageNotFoundText:
      'La pagina che hai richiesto non è stata trovata. Usa la nostra funzione di ricerca per trovare il contenuto desiderato.',
    serverErrorTitle: 'Pagina non disponibile',
    goToHome: 'Vai alla homepage',
  },
}

type ErrorObject = {
  url?: string
  statusCode?: number
  statusMessage?: string
  message?: string
  stack?: string
}

const language = computed<string>(() => {
  const v = route.params.language
  if (v) {
    if (typeof v === 'string') {
      return v
    }
  }

  return 'en'
})

const texts = computed<ErrorMessages>(() => {
  if (language.value && ERRORS[language.value]) {
    return ERRORS[language.value]
  }
  return ERRORS.de
})

const props = defineProps<{
  error: ErrorObject
}>()

const title = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedTitle
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundTitle
  }

  return texts.value.serverErrorTitle
})

const text = computed(() => {
  if (props.error?.statusCode === 403) {
    return texts.value.accessDeniedText
  } else if (props.error?.statusCode === 404) {
    return texts.value.pageNotFoundText
  }

  return props.error?.message
})

const goToHome = computed(() => {
  return texts.value.goToHome
})

const frontUrl = computed(() => {
  return '/' + language.value
})

// Data fetching should not be used on the error page, because this could trigger another error (e.g. when Drupal is down).
// This would result in an infinite loop.

// get custom error page for the 404 status code
// const { data: query } = await useAsyncData('/' + language.value + '/error', async () =>
//   useGraphqlQuery('route', {
//     path: '/' + language.value + '/error',
//   }).then((v) => v.data),
// )

// const isErrorNotFound = computed(() =>  {
//   return props.error?.statusCode === 404
// })

// const { entity } = await useDrupalRoute<
//   NodePageFragment | NodeBlogArticleFragment | GroupFragment
// >(query.value)

onMounted(() => {
  clearError()
})
</script>
