<template>
  <img
    :src="src"
    :loading="loading"
    :srcset="srcset"
    :sizes="sizes"
    :alt="alt"
    :title="title"
    itemprop="image"
    class="block w-full"
  />
</template>

<script lang="ts" setup>
import type { PropType } from 'vue'
import { ImageStyleSizes, VIEWPORTS, DPR } from '#vuepal/rokka'

const config = useRuntimeConfig()

const props = defineProps({
  imageStyle: {
    type: Object as PropType<ImageStyleSizes>,
    default: null,
  },
  alt: {
    type: String,
    default: '',
  },
  title: {
    type: String,
    default: '',
  },
  hash: {
    type: String,
    default: '',
  },
  filename: {
    type: String,
    default: '',
  },
  loading: {
    type: String,
    default: '',
  },
  host: {
    type: String,
    default: '',
  },
  sourceWidth: {
    type: Number as PropType<number | undefined | null>,
    default: 0,
  },
  sourceHeight: {
    type: Number as PropType<number | undefined | null>,
    default: 0,
  },
})

const images = computed(() => {
  return props.imageStyle.stacks
    .map((stack) => {
      return DPR.map((v) => {
        return {
          src: buildRokkaUrl(
            stack.name,
            v,
            props.hash,
            props.filename,
            props.host || config.public.rokkaHost,
            config.public.imageHash
          ),
          width: stack.width * (v ? 2 : 1),
        }
      })
    })
    .flat()
})

const sizes = computed(() => {
  if (!props.imageStyle) {
    return ''
  }

  const viewport = props.imageStyle.viewport

  return Object.keys(viewport)
    .map((key) => {
      // @ts-ignore
      const minWidth = VIEWPORTS[key as any] - 1
      // @ts-ignore
      const width: number = viewport[key]

      return { width, minWidth }
    })
    .sort((a, b) => b.minWidth - a.minWidth)
    .map((v) => {
      return v.minWidth < 0
        ? v.width + 'px'
        : `(min-width: ${v.minWidth}px) ${v.width}px`
    })
    .join(', ')
})

const src = computed(() => {
  return images.value[0] ? images.value[0].src : ''
})

const srcset = computed(() => {
  return images.value
    .map((v) => {
      return `${v.src} ${v.width}w`
    })
    .join(', ')
})
</script>
