<template>
  <Transition name="overlay" appear>
    <Overlay small @close="dismiss" v-if="countryData">
      <div class="text-center">
        <h1 class="is-1 pb-30">{{ countryData.popupTitle }}</h1>

        <div v-if="countryData.popupText" class="content pb-10">
          <div v-html="countryData.popupText" />
        </div>

        <div class="mb-10">
          <DrupalLink
            v-if="countryData.shopLink && countryData.shopLink.uri?.path"
            :to="countryData.shopLink.uri.path"
            class="btn text-center"
            >{{ countryData.shopLink.title }}</DrupalLink
          >
        </div>
        <button class="btn is-outline" @click="dismiss">
          {{
            $texts('countryPopup.stayOnPageLabel', 'Stay on the current page')
          }}
        </button>
      </div>
    </Overlay>
  </Transition>
</template>

<script lang="ts" setup>
import { CountryPopupTermFragment } from '#build/graphql-operations'

const { data } = await useInitData()
const { country } = useCountry()
const cookie = useCookie('exp_popup_closed')
const isDismissed = computed(() => cookie.value === 'yes')
const countryWithShop = computed(
  () =>
    data.value.countries.find((v) => v.code === country.value && v.hasShopLink)
      ?.termId,
)

const { data: countryData } =
  await useAsyncData<CountryPopupTermFragment | null>(
    () => {
      if (countryWithShop.value && !isDismissed.value) {
        return useGraphqlQuery('countryPopup', {
          id: countryWithShop.value,
        }).then((v) =>
          v.data.entityById && 'id' in v.data.entityById
            ? v.data.entityById
            : null,
        )
      }
      return Promise.resolve(null)
    },
    {
      watch: [countryWithShop, isDismissed],
    },
  )

const dismiss = () => (cookie.value = 'yes')
</script>
