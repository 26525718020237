<template>
  <div
    class="sticky top-0 z-[100] bg-white px-15 py-10 md:p-0 md:bg-transparent"
  >
    <ClientOnly>
      <AdminToolbar v-if="showAdminToolbar" :key="language" />
      <UserToolbar v-if="showUserToolbar" :key="language" />
    </ClientOnly>

    <div class="flex relative z-[100]" ref="root">
      <div
        class="flex md:block lg:flex md:absolute md:top-0 md:left-0 md:pt-15 md:pl-10 lg:pl-[12px] lg:top-10"
      >
        <AppHeaderButton
          menu-key="menu"
          :label="$texts('headerOpenMenu', 'Open Menu')"
          :open="open"
          @toggle-key="toggle"
        />
        <AppHeaderButton
          menu-key="search"
          :label="$texts('headerOpenSearch', 'Open Search')"
          :open="open"
          @toggle-key="toggle"
        />
      </div>
      <Logo />
    </div>

    <AppHeaderOverlay :visible="open === 'menu'" :top="topOffset">
      <AppHeaderMenu />
    </AppHeaderOverlay>

    <AppHeaderOverlay :visible="open === 'search'" :top="topOffset">
      <AppHeaderSearch :is-visible="open === 'search'" />
    </AppHeaderOverlay>

    <Transition name="menu-fade">
      <div
        v-if="open"
        class="fixed left-0 w-full h-full z-[80] bg-white"
        :style="{
          top: topOffset + 'px',
        }"
      />
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { onKeyStroke } from '@vueuse/core'
import type { MenuKey } from './Button/index.vue'
import { clearAllBodyScrollLocks } from 'body-scroll-lock'

const route = useRoute()
const drupalUser = useDrupalUser()
const language = useCurrentLanguage()

const root = ref<HTMLDivElement | null>(null)

const topOffset = ref(0)

const setTopOffset = () => {
  if (!root.value) {
    return
  }
  topOffset.value = root.value.getBoundingClientRect().top
}

// The currently open menu.
const open = ref<MenuKey | null>(null)

// Toggle the open menu.
const toggle = (key: MenuKey) => {
  setTopOffset()
  open.value = open.value === key ? null : key
}

// Close any open menu.
const close = () => (open.value = null)

// Close menu when pressing the escape key.
onKeyStroke('Escape', close)

// Close menu when switching pages.
watch(() => route.fullPath, close)

watch(open, (newOpen) => {
  if (!newOpen) {
    clearAllBodyScrollLocks()
  }
})

const isEditing = computed(
  () =>
    !!(route.query.blokkliEditing || route.query.blokkliPreview) &&
    drupalUser.canUseParagraphsBuilder,
)

const showAdminToolbar = computed(
  () => drupalUser.canAccessToolbar.value && !isEditing.value,
)
const showUserToolbar = computed(() => {
  return (
    drupalUser.isLoggedIn.value &&
    !isEditing.value &&
    !drupalUser.canAccessToolbar.value
  )
})
</script>

<style lang="postcss">
.menu-enter-active,
.menu-leave-active {
  transition: 0.2s cubic-bezier(0.43, 0.72, 0.56, 0.99);
}
.menu-enter-from,
.menu-leave-to {
  transform: translateY(-5rem);
  opacity: 0;
}

.menu-fade-enter-active,
.menu-fade-leave-active {
  transition: 0.2s cubic-bezier(0.43, 0.72, 0.56, 0.99);
}
.menu-fade-enter-from,
.menu-fade-leave-to {
  opacity: 0;
}
</style>
