<template>
  <div class="hidden lg:block">
    <VuepalAdminToolbar />
    <div
      class="p-10 flex justify-between items-center border-b border-b-gray bg-white"
    >
      <VuepalLocalTasks />
      <AdminToolbarSwitcher />
    </div>
  </div>
</template>

<style lang="postcss">
.admin-toolbar-overlay {
  z-index: 99 !important;
}

.bk-body .bk-main-canvas {
  max-width: 2800px !important;
}

/* Highlight paragraphs in editor that have region settings applied. */
.bk-main-canvas:hover .bk-paragraph-region-affected {
  box-shadow: 0 0 0 3px #f59e0b;
}

.bk-paragraph-region-hidden {
  @apply !h-20 !overflow-hidden !my-0 !opacity-10 !pointer-events-none;
}

.bk-grid-overlay {
  @apply fixed top-0 left-0 w-full z-[99999999] h-full pointer-events-none;
  .container {
    @apply grid grid-cols-12 gap-30 h-full;
    > div {
      @apply h-full  border-l-[0.75px] border-l-black/40 border-r-[0.75px] border-r-black/40;
    }
  }
}

.bk-edit-indicator {
  height: 0;
  button {
    @apply origin-top-right;
    transform: rotate(90deg) translateX(510px) translateY(15px);
    @screen 3xl {
      transform: rotate(90deg) translateX(610px) translateY(24px);
    }
  }
}

.slider-paragraph-list {
  @apply grid grid-cols-10 justify-center gap-10 bg-gray p-30 !absolute top-0 left-0 z-30 opacity-0 w-full;
}

.bk-main-canvas:hover .slider-paragraph-list {
  @apply opacity-30;
}
.bk-main-canvas:hover .draggable:hover .slider-paragraph-list {
  @apply opacity-100;
}
</style>
