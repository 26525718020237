<template>
  <footer class="container mb-40 mt-100 lg:mt-130">
    <div
      class="pt-60 border-t lg:border-t-3 md:grid md:grid-cols-2 lg:grid-cols-3 space-y-60 md:space-y-0 md:gap-30"
    >
      <div
        class="lg:col-span-2 lg:grid lg:grid-cols-2 space-y-60 lg:space-y-0 lg:gap-30"
      >
        <div v-if="main.length" class="space-y-60">
          <ul class="space-y-30">
            <li v-for="(link, index) in main" :key="`mainlink-${index}`">
              <DrupalLink
                :to="link.to"
                class="link is-small heavy"
                v-bind="link.attributes"
                >{{ link.label }}</DrupalLink
              >
            </li>
          </ul>
        </div>
        <div v-if="meta.length" class="space-y-60">
          <ul class="space-y-30">
            <li v-for="(link, index) in meta" :key="`metalink-${index}`">
              <DrupalLink
                :to="link.to"
                v-bind="link.attributes"
                class="link is-small heavy"
                >{{ link.label }}</DrupalLink
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="space-y-60">
        <div class="space-y-30">
          <AppFooterSocial />
        </div>
        <ul v-if="footer" class="space-y-10">
          <li
            v-for="(link, index) in footer"
            :key="`footer-${index}`"
            class="is-small"
          >
            <DrupalLink
              :to="link.to"
              v-bind="link.attributes"
              class="link underline"
              >{{ link.label }}</DrupalLink
            >
          </li>
        </ul>
        <div>
          <p class="is-small">&copy; EXPED {{ year }}</p>
        </div>
      </div>
    </div>
    <div class="inline-block border-t-1 mt-100 lg:mt-130 pt-10">
      <p class="text-sm font-light uppercase">
        Refined Gear<br />for Adventure
      </p>
    </div>
  </footer>
</template>

<script lang="ts" setup>
import { FooterMenuLinkFragment } from '#build/graphql-operations'
import { falsy } from '~/helpers/type'
import { Region } from '~/settings'
import { set } from '@vueuse/core'

const language = useCurrentLanguage()

const { data } = await useLazyAsyncData(
  () => useGraphqlQuery('footer').then((v) => v.data),
  {
    watch: [language],
  },
)
const { region } = useCountry()

type FooterLink = {
  to: string
  label: string
  attributes: Record<string, string>
}

function mapLinks(links: FooterMenuLinkFragment[] = []): FooterLink[] {
  return links
    .map((link) => {
      if (link.link.url?.path && link.link.label) {
        const footerLink: FooterLink = {
          to: link.link.url.path,
          label: link.link.label,
          attributes: {},
        }
        if (link.link.target) {
          footerLink.attributes.target = link.link.target
        }
        if (link.link.url.path === 'https://werkstattshop-ch.exped.com/') {
          switch (region.value) {
            case Region.DAFI:
              footerLink.to = link.link.url.path = 'https://werkstattshop-de.exped.com/'
              break
            case Region.Switzerland:
              break
            default:
              return // Don't push link to array
          }
        }
        return footerLink
      }
    })
    .filter(falsy)
}

const main = computed(() => mapLinks(data.value?.mainMenu?.links))
//const meta = computed(() => mapLinks(data.value?.metaNavigation?.links))
let meta = ref()
const footer = computed(() => mapLinks(data.value?.footerNavigation?.links))
const year = computed(() => new Date().getFullYear())

function setMeta() {
  meta = computed(() => mapLinks(data.value?.metaNavigation?.links))
}

setMeta()

watch(region, () => {
  setMeta()
})
</script>

<style lang="postcss">
.footer-content,
.footer-contact {
  @apply text-sm;
}

.footer-contact {
  a {
    @apply hover:opacity-60;
  }
}
</style>
