import type { InitData } from '~/server/api/initData'

type UseInitData = {
  data: globalThis.Ref<InitData>
}

export default async function (): Promise<UseInitData> {
  const app = useNuxtApp()
  const currentLanguage = useCurrentLanguage()
  const data = useState<InitData | null>('initData', () => null)
  if (data.value) {
    return { data } as any
  }

  async function loadInitData(language: string) {
    const loaded = await $fetch('/api/initData', {
      query: {
        language: language,
        __server: process.server || undefined,
      },
    })

    useRouteCache(
      (c) => {
        try {
          // @ts-ignore
          const tags = loaded.__cacheability.tags

          if (!tags) {
            throw new Error()
          }
          c.setCacheable().addTags(tags)
          // @ts-ignore
          delete loaded.__cacheability
        } catch (e) {
          c.setUncacheable()
        }
      },
      app.ssrContext?.event,
    )

    data.value = loaded
  }

  await loadInitData(currentLanguage.value)

  if (process.client) {
    watch(currentLanguage, (newLanguage) => {
      loadInitData(newLanguage)
    })
  }

  return { data } as any
}
