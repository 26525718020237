<template>
  <button
    :aria-label="label"
    class="block hover:opacity-60 p-10"
    :class="{ 'opacity-20': isDisabled }"
    @click="$emit('toggleKey', menuKey)"
  >
    <ResponsiveSpriteSymbol
      v-if="isActive"
      name="cross"
      class="w-[17px] h-[17px]"
    />
    <ResponsiveSpriteSymbol v-else :name="menuKey" class="w-[17px] h-[17px]" />
  </button>
</template>

<script lang="ts" setup>
export type MenuKey = 'menu' | 'search'

const props = defineProps<{
  menuKey: MenuKey
  label: string
  open: string | null
}>()
defineEmits<{
  (e: 'toggleKey', data: MenuKey): void
}>()

const isActive = computed(() => props.menuKey === props.open)
const isDisabled = computed(() => props.open && !isActive.value)
</script>
