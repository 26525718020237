<template>
  <div class="dropdown relative w-full lg:w-1/2" v-click-away="close">
    <button
      ref="dropdownToggle"
      aria-label="Toggle Dropdown"
      type="button"
      class="dropdown-toggle relative flex w-full justify-between items-center pt-5 pb-[7px] text-xl border-b-1 bg-white"
      :class="{ 'z-20': isOpen }"
      @click="isOpen = !isOpen"
    >
      <span v-if="selectedOption" class="truncate">{{
        selectedOption.label
      }}</span>

      <SpriteSymbol
        name="chevron-down"
        class="transition-transform duration-300 transform w-[12px] h-[8px]"
        :class="isOpen ? 'rotate-180' : 'rotate-0'"
      />
    </button>
    <Transition name="dropdown-content">
      <div
        class="dropdown-content absolute z-10 left-0 w-full pt-5 bg-white overflow-hidden"
        v-show="isOpen"
      >
        <ul>
          <li
            v-for="option in options"
            :key="option.value"
            class="py-5 cursor-pointer opacity-100 hover:opacity-60 transition-opacity"
            :class="{ 'font-medium': option.value === selectedOption?.value }"
            @click="selectOption(option.value)"
          >
            <NuxtLink
              v-if="option.href"
              :to="option.href"
              class="block w-full"
              >{{ option.label }}</NuxtLink
            >
            <span v-else>{{ option.label }}</span>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import { directive as vClickAway } from 'vue3-click-away'
import type { RouteLocationNamedRaw } from 'vue-router'

export type DropdownOption = {
  value?: string
  label?: string
  href?: RouteLocationNamedRaw
  title?: string
}

const props = defineProps<{
  options: DropdownOption[]
  modelValue?: string
}>()

const isOpen = ref(false)

const close = () => (isOpen.value = false)

// @TODO: Router manipulation magic

const emit = defineEmits(['update:modelValue'])

const selectedOption = computed(() =>
  props.options.find((v) => v.value === props.modelValue),
)

const selectOption = (value?: string) => {
  if (value) {
    emit('update:modelValue', value)
  }
  isOpen.value = false
}
</script>

<style lang="postcss">
.dropdown-content-enter-active,
.dropdown-content-leave-active {
  transition: 0.2s cubic-bezier(0.43, 0.72, 0.56, 0.99);
  > ul {
    transition: inherit;
  }
}
.dropdown-content-enter-from,
.dropdown-content-leave-to {
  opacity: 0;
  > ul {
    transform: translateY(-100%);
  }
}
</style>
